import { Message } from "ant-design-vue";
import { ABANDON_MARK, isOverCallRequest } from "./fetchDebounce";

const COMMON_NOTIFICATION = "请求异常，请稍后再试！";

const successReponseCode = 0;

const noTicketReponseCode = -90800015;

const serviceWhite = [];

const goLogin = () => {
  // const href = encodeURICompyarnonent(window.location.href);
  localStorage.setItem('redirectUrl', window.location.href)
  window.location.href =
    "https://openapi.jkinvestcs.cn/ui/portal?platformId=0&deviceOsType=0&deviceId=A712B9AC983D44F58AEAA50F005057BA&deviceModel=h5&deviceOsVersion=0&appName=618&appVersionCode=0&appVersionName=0&appId=618&screenDensity=0&screenResolution=1920x1080&redirectUrl=https://hy.jkinvestcs.cn/mc/api/loginWithTmpToken";
};

const checkServiceWhite = (url) => {
  for (const value of serviceWhite) {
    if (url.indexOf(value) > -1) {
      return true;
    }
  }
  return false;
};

const requestSuccess = (config) => {
  if (isOverCallRequest(config)) {
    return Promise.reject({ mark: ABANDON_MARK, url: config.url });
  }
  return config;
};

const requestError = (error) => {
  return Promise.reject(error);
};

const responseSuccess = (response) => {
  if (checkServiceWhite(response.config.url || "")) {
    return response;
  }
  const { data = {} } = response;
  const { resultCode, resultMsg } = data;
  if (resultCode !== successReponseCode) {
    if (resultCode === noTicketReponseCode) {
      goLogin();
    } else {
      Message.error(resultMsg || COMMON_NOTIFICATION);
      return Promise.reject(response);
    }
  }
  return response;
};

const responseError = (error) => {
  if (error?.mark === ABANDON_MARK) {
    return Promise.reject(error);
  }
  Message.error(COMMON_NOTIFICATION);
  return Promise.reject(error);
};

export { requestSuccess, requestError, responseSuccess, responseError };
