<template>
  <div id="app">
    <div id="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>