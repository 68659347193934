import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import service from "./fetch";
import store from "./store";
import cookies from "vue-cookies";
import { ABANDON_MARK } from "./fetch/fetchDebounce";
import {
  Button,
  Drawer,
  Message,
  Carousel,
  Input,
  Icon,
  Switch,
  Select,
  Dropdown,
  Menu,
  Tabs,
  FormModel,
  Modal,
} from "ant-design-vue";
import "@/assets/style/normalize.scss";
import "@/assets/style/main.scss";
import "@/assets/style/lib-ui-cover.scss";
import "@/assets/iconfont/iconfont.css";

Vue.config.productionTip = false;

[
  Button,
  Drawer,
  Carousel,
  Input,
  Icon,
  Switch,
  Select,
  Dropdown,
  Menu,
  Tabs,
  FormModel,
  Modal,
].forEach((C) => {
  Vue.use(C);
});

Vue.prototype.$message = Message;
Vue.prototype.$modal = Modal;
Vue.prototype.$service = service;
Vue.prototype.$cookies = cookies;
Vue.prototype.$eventBus = new Vue();

Vue.config.errorHandler = function(error) {
  if (error?.mark === ABANDON_MARK) {
    console.log(`请求：${error?.url} 过渡调用,已丢弃。`);
  }
  console.error(error);
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
