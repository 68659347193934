import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const getJSONValueOfLocalStorage = (key, defaultValue) => {
  try {
    return JSON.parse(localStorage.getItem(key) || "{}");
  } catch (error) {
    return defaultValue;
  }
};

export default new Vuex.Store({
  state: {
    userInfo: getJSONValueOfLocalStorage("userInfo", {}),
    merchantId: localStorage.getItem("merchantId"),
    appId: localStorage.getItem("appId"),
  },
  mutations: {
    updateMerchantId(state, merchantId) {
      state.merchantId = merchantId;
      localStorage.setItem("merchantId", merchantId);
    },
    updateAppId(state, appId) {
      state.appId = appId;
      localStorage.setItem("appId", appId);
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
  },
  actions: {},
  modules: {},
});
