import Vue from "vue";
import VueRouter from "vue-router";
import Transfer from "@/components/Transfer";

Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    name: "Default",
    redirect: "/index",
  },
  {
    path: "/page-viewer",
    component: () => import("../views/PageViewer"),
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("../views/Home/index"),
    meta: {
      name: "首页",
    },
  },
  {
    path: "/my",
    name: "My",
    component: () => import("../views/My"),
    meta: {
      name: "个人中心",
    },
  },
  {
    path: "/vip-viewer",
    name: "VipViewer",
    component: () => import("../views/VipViewer"),
    meta: {
      name: "会员系统",
    },
  },
  {
    path: "/bindCard",
    name: "bindCard",
    component: () => import("../views/BindCard/index"),
    meta: {
      name: "绑卡",
    },
  },
  {
    path: "/consumptionRecord",
    name: "consumptionRecord",
    component: () => import("../views/ConsumptionRecord/index"),
    meta: {
      name: "消费记录",
    },
  },
  {
    path: "/card-viewer",
    name: "CardViewer",
    component: () => import("../views/CardViewer"),
    meta: {
      name: "卡详情",
    },
  },
  {
    path: "/dialogBox",
    name: "dialogBox",
    component: () => import("../views/DialogBox/index"),
    meta: {
      name: "对话框",
    },
  },
  {
    path: "/manageHealthCard",
    name: "manageHealthCard",
    component: () => import("../views/ManageHealthCard/index"),
    meta: {
      name: "管理健康卡",
    },
  },
  {
    path: "/benefitDetail",
    name: "benefitDetail",
    component: () => import("../views/BenefitDetail/index"),
    meta: {
      name: "权益详情",
    },
  },
  {
    path: "/merchantBenefits",
    name: "merchantBenefits",
    redirect: "/merchantBenefits/200",
    component: Transfer,
    children: [
      {
        path: "/merchantBenefits/:merchantId",
        name: "merchantBenefits-son",
        component: () => import("../views/MerchantBenefits"),
        meta: {
          name: "全部权益",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
